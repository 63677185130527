import * as React from 'react';
import { useMemo } from 'react';
import { graphql, Link } from 'gatsby';
import LandingLayout from '../layouts/landing-layout/landing-layout';
import ParsedContent from '../components/parsed-content/parsed-content';
import ProjectList from '../components/project-list/project-list';
import LandingSlider from '../components/landing-slider/landing-slider';

import * as styles from '../styles/pages/index.module.scss';

const IndexPage = ({ data }) => {
	const mapUrl = useMemo(() => {
		const url = data?.api?.hp?.mapCode?.match(
			/src="(https:\/\/(www\.)?google\.com\/maps\/embed.*?)"/
		);
		return url !== null && url?.length > 1 ? url[1] : null;
	}, [data]);

	return (
		<LandingLayout
			lightHeader={true}
			useH1InHeader={true}
			seo={{ description: data?.api?.seo?.siteDescription }}
			landingElement={<LandingSlider images={data?.api?.hp?.sliderImages ?? []} />}
		>
			<section className={styles.aboutUsSection}>
				<h2>O Nás</h2>

				<ParsedContent html={data?.api?.hp?.aboutUsText} />

				<Link to="/o-nas" className="btn-border-dark">
					Více o nás
				</Link>
			</section>

			<section className={styles.selectedProjectsSection}>
				<h2>Realizace</h2>

				<ProjectList singleRow={true} projects={data?.api?.hp?.selectedProjects ?? []} />

				<Link to="/realizace" className="btn-border-dark">
					Zobrazit všechny realizace
				</Link>
			</section>

			<section data-navigation-id="kontakt" className={styles.contactSection}>
				<h2>Kontaktujte nás</h2>

				<div className={styles.contactInfo}>
					{mapUrl && (
						<iframe
							title="Mapa"
							loading="lazy"
							className={styles.map}
							allowFullScreen={true}
							src={mapUrl}
							role="application"
						/>
					)}

					{data?.api?.hp?.contactInfoLeft?.length > 0 && (
						<ParsedContent
							className={styles.contactInfoLeft}
							html={data?.api?.hp?.contactInfoLeft}
						/>
					)}

					{data?.api?.hp?.contactInfoRight?.length > 0 && (
						<ParsedContent
							className={styles.contactInfoRight}
							html={data?.api?.hp?.contactInfoRight}
						/>
					)}

					{data?.api?.hp?.employeesInfo?.length > 0 && (
						<ParsedContent className={styles.employeesInfo} html={data?.api?.hp?.employeesInfo} />
					)}
				</div>
			</section>
		</LandingLayout>
	);
};

export const query = graphql`
	query {
		api {
			seo: seoSingleton {
				siteDescription
			}

			hp: homepageSingleton {
				aboutUsText
				sliderImages {
					path
					meta
					file {
						childImageSharp {
							gatsbyImageData(layout: FULL_WIDTH)
						}
					}
				}
				selectedProjects(sort: { _o: 1 }) {
					title
					slug
					categories
					gallery {
						path
						meta
						file {
							childImageSharp {
								gatsbyImageData(layout: FULL_WIDTH)
							}
						}
					}
				}
				mapCode
				contactInfoLeft
				contactInfoRight
				employeesInfo
			}
		}
	}
`;

export default IndexPage;
