import * as React from 'react';
import { useRef } from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Swiper, SwiperSlide } from 'swiper/react';
import PropTypes from 'prop-types';
import { Timeline, Tween } from 'react-gsap';
import { gsap } from 'gsap';
import { useForceRender } from '../../hooks/force-render';

import * as styles from './landing-slider.module.scss';

const LandingSlider = ({ images }) => {
	const forceRender = useForceRender();

	const landingSlider = useRef(null);
	const paginationRef = useRef(null);

	const onActionButtonClick = () => {
		const headerEl = document?.querySelector('[data-navigation-id=header]');

		gsap.to(window, {
			duration: 1,
			ease: 'power3.out',
			scrollTo:
				landingSlider?.current?.offsetTop +
				landingSlider?.current?.offsetHeight -
				headerEl?.offsetHeight,
		});
	};

	return (
		<div ref={landingSlider} className={styles.landingSliderContainer}>
			<Swiper
				slidesPerView={1}
				speed={810}
				autoplay={{
					delay: 3000,
				}}
				className={styles.landingSlider}
				pagination={{
					bulletClass: styles.paginationBullet,
					bulletActiveClass: styles.active,
					el: paginationRef.current,
					clickable: true,
				}}
				onInit={() => {
					// Needed to correctly link navigation refs
					forceRender();
				}}
			>
				{images?.map((image, i) => (
					<SwiperSlide key={`landingSlide${i}`} className={styles.slide}>
						<GatsbyImage
							title={image?.meta?.title}
							alt={image?.meta?.alt ?? ''}
							image={getImage(image?.file)}
							className={styles.image}
						/>
					</SwiperSlide>
				))}
			</Swiper>

			<div className={styles.pagination}>
				<div ref={paginationRef} className={styles.flexContainer} />
			</div>

			<Timeline
				target={
					<button
						className={styles.actionBtn}
						onClick={onActionButtonClick}
						aria-label="Posunout dolů"
					>
						<i className="fal fa-chevron-double-down" />
					</button>
				}
				repeat={-1}
				repeatDelay={2}
			>
				<Tween from={{ y: 0 }} to={{ y: -30 }} duration={0.5} />
				<Tween to={{ y: 0 }} duration={2.5} ease="elastic.out(1, 0.25)" />
			</Timeline>
		</div>
	);
};

LandingSlider.defaultProps = {
	images: [],
};

LandingSlider.propTypes = {
	images: PropTypes.arrayOf(
		PropTypes.shape({
			path: PropTypes.string,
			meta: PropTypes.any,
			file: PropTypes.any.isRequired,
		})
	),
};

export default LandingSlider;
